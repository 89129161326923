import { DropdownButton } from 'react-bootstrap';
import { BsJournals } from 'react-icons/bs';
import TextIcon from '../../components/textIcon';
import SavedQuery from './savedQuery';

// interface ISavedQueriesProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const SavedQueries = () => {
	return (
		<DropdownButton
			variant="secondary"
			title={
				<TextIcon Icon={BsJournals} inline>
					<span className="d-none d-xl-inline">Запити</span>
				</TextIcon>
			}
			id="ddSavedQueries"
			align="end"
		>
			{SAVED_QUERIES.map(({ title, key_words }) => (
				<SavedQuery key={title} title={title} term={key_words.join(' або ')} />
			))}
		</DropdownButton>
	);
};

export default SavedQueries;

interface ISavedQuery {
	title: string;
	key_words: string[];
}
const SAVED_QUERIES: ISavedQuery[] = [
	{
		title: 'Президент України',
		key_words: [
			'"Президент України"',
			'"Президент Украины"',
			'"Президент Зеленський"',
			'"Президент Зеленский"',
			'"Володимир Зеленський"',
			'"Владимир Зеленский"',
		],
	},
	{
		title: 'СБУ',
		key_words: [
			'сбу',
			'усбу',
			'"служба безпеки"',
			'"служба безопасности украины"',
			'"василий малюк"',
			'"Security Service of Ukraine"',
			'SBU',
			'SSU',
			'"ЦСО А"',
		],
	},
	{
		title: 'ЗСУ',
		key_words: [
			'зсу',
			'всу',
			'"збройні сили України"',
			'"оперативне командування"',
			'"вооруженные силы Украины"',
			'залужний',
			'"ганна маляр"',
			'"наталія гуменюк"',
		],
	},
];
